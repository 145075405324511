var items = [
    {
        "id": "0",
        "iconUrl": "https://cdn.mos.cms.futurecdn.net/42E9as7NaTaAi4A6JcuFwG-1200-80.jpg",
        "itemName": "BANAN",
        "itemNum": "3",
        "inDate": "2020-10-10",
        "outDate": "2020-10-12",
        "expiration": "7",
        "mainURL": "https://cdn.the-scientist.com/assets/articleNo/42182/aImg/35036/bananas-thumb-l.png",
        "note":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        "id": "1",
        "iconUrl": "https://media.istockphoto.com/photos/red-apple-with-leaf-picture-id683494078?k=6&m=683494078&s=612x612&w=0&h=aVyDhOiTwUZI0NeF_ysdLZkSvDD4JxaJMdWSx2p3pp4=",
        "itemName": "APPLE",
        "itemNum": "4",
        "inDate": "2020-10-12",
        "outDate": "2020-10-18",
        "expiration": "7",
        "mainURL": "https://image.shutterstock.com/image-photo/red-apple-on-white-background-260nw-158989157.jpg",
        "note":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        "id": "2",
        "iconUrl": "https://cdn.mos.cms.futurecdn.net/42E9as7NaTaAi4A6JcuFwG-1200-80.jpg",
        "itemName": "BANAN",
        "itemNum": "3",
        "inDate": "2020-10-10",
        "outDate": "2020-10-12",
        "expiration": "7",
        "mainURL": "https://cdn.the-scientist.com/assets/articleNo/42182/aImg/35036/bananas-thumb-l.png",
        "note":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        "id": "3",
        "iconUrl": "https://media.istockphoto.com/photos/red-apple-with-leaf-picture-id683494078?k=6&m=683494078&s=612x612&w=0&h=aVyDhOiTwUZI0NeF_ysdLZkSvDD4JxaJMdWSx2p3pp4=",
        "itemName": "APPLE",
        "itemNum": "4",
        "inDate": "2020-10-12",
        "outDate": "2020-10-18",
        "expiration": "7",
        "mainURL": "https://image.shutterstock.com/image-photo/red-apple-on-white-background-260nw-158989157.jpg",
        "note":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        "id": "4",
        "iconUrl": "https://cdn.mos.cms.futurecdn.net/42E9as7NaTaAi4A6JcuFwG-1200-80.jpg",
        "itemName": "BANAN",
        "itemNum": "3",
        "inDate": "2020-10-10",
        "outDate": "2020-10-12",
        "expiration": "7",
        "mainURL": "https://cdn.the-scientist.com/assets/articleNo/42182/aImg/35036/bananas-thumb-l.png",
        "note":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        "id": "5",
        "iconUrl": "https://media.istockphoto.com/photos/red-apple-with-leaf-picture-id683494078?k=6&m=683494078&s=612x612&w=0&h=aVyDhOiTwUZI0NeF_ysdLZkSvDD4JxaJMdWSx2p3pp4=",
        "itemName": "APPLE",
        "itemNum": "4",
        "inDate": "2020-10-12",
        "outDate": "2020-10-18",
        "expiration": "7",
        "mainURL": "https://image.shutterstock.com/image-photo/red-apple-on-white-background-260nw-158989157.jpg",
        "note":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        "id": "6",
        "iconUrl": "https://cdn.mos.cms.futurecdn.net/42E9as7NaTaAi4A6JcuFwG-1200-80.jpg",
        "itemName": "BANAN",
        "itemNum": "3",
        "inDate": "2020-10-10",
        "outDate": "2020-10-12",
        "expiration": "7",
        "mainURL": "https://cdn.the-scientist.com/assets/articleNo/42182/aImg/35036/bananas-thumb-l.png",
        "note":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        "id": "7",
        "iconUrl": "https://media.istockphoto.com/photos/red-apple-with-leaf-picture-id683494078?k=6&m=683494078&s=612x612&w=0&h=aVyDhOiTwUZI0NeF_ysdLZkSvDD4JxaJMdWSx2p3pp4=",
        "itemName": "APPLE",
        "itemNum": "4",
        "inDate": "2020-10-12",
        "outDate": "2020-10-18",
        "expiration": "7",
        "mainURL": "https://image.shutterstock.com/image-photo/red-apple-on-white-background-260nw-158989157.jpg",
        "note":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    }
]


// function getAllItems() {
//   var request = require('request');
//   var items = []
//   var options = {
//     'method': 'GET',
//     'url': 'https://wdrd6suw5h.execute-api.us-east-1.amazonaws.com/test/item',
//     'headers': {
//     }
//   };
//   request(options, function (error, response) {
//     if (error) throw new Error(error);
//     items = response.body;
//     console.log(items);
//     return items
//   });
//   return items
  
// }



export default items;